import React from "react";
import { Container, Section, SectionTitle } from "../Section";
import { TechImageList } from "../Technologies";
import { getCaseStudyUrl, getCaseStudiesUrl } from "../../utils/urls";
import {
  MdxTestimonialsData,
  mdxToTestimonial,
} from "../Testimonials/MdxTestimonials";
import { NextPrev } from "../NextPrev";
import { ImageType, MdxImageRaw } from "../../types/images";
import { Technology } from "../../utils/tech";
import { MdxNextPrev } from "../../types/mdx";
import { Article, ArticleContent, ArticleHeader } from "../Article";
import { PageFeatured } from "../PageFeatured";
import { Image } from "../Image";
import { ShareButtons } from "../ShareButtons";
import { ZoomImage } from "../ZoomImage";
import { PageHeader, PageTitle } from "../PageHeader";
import { Link } from "../Link";
import { Testimonial } from "../Testimonials/Testimonial";

type Props = {
  slug: string;
  title: string;
  description: string;
  websiteUrl?: string;
  hero?: string;
  featured: ImageType & MdxImageRaw;
  tech: Technology[];
  testimonial: MdxTestimonialsData;
  prev?: MdxNextPrev;
  next?: MdxNextPrev;
};

export const CaseStudy: React.FC<Props> = ({
  slug,
  title,
  description,
  websiteUrl,
  featured,
  children,
  tech,
  testimonial,
  prev,
  next,
}) => {
  return (
    <>
      <PageFeatured>
        <ZoomImage zoomImage={featured.publicURL}>
          <Image image={featured} alt="Featured Image" />
        </ZoomImage>
      </PageFeatured>

      <Container>
        <Article>
          <ArticleHeader>
            <Link to={getCaseStudiesUrl()}>Case Study</Link>
            <PageTitle>{title}</PageTitle>

            <ShareButtons
              url={getCaseStudyUrl(slug)}
              title={title}
              quote={description}
            />
          </ArticleHeader>

          <ArticleContent>{children}</ArticleContent>

          {!!websiteUrl && (
            <Link ext to={websiteUrl}>
              Visit Website
            </Link>
          )}
          <ShareButtons
            url={getCaseStudyUrl(slug)}
            title={title}
            quote={description}
          />
        </Article>
      </Container>
      {!!testimonial.nodes.length && (
        <Section>
          <SectionTitle>Feedback Received</SectionTitle>
          <Testimonial
            {...{ ...mdxToTestimonial(testimonial.nodes[0]), caseUrl: "" }}
          />
        </Section>
      )}
      {!!(tech && tech.length) && (
        <Section>
          <SectionTitle>Technologies</SectionTitle>
          <TechImageList tech={tech} />
        </Section>
      )}
      <Container>
        <NextPrev
          prev={
            prev
              ? {
                  to: getCaseStudyUrl(prev.slug),
                  title: `Case Study: ${prev.title}`,
                }
              : undefined
          }
          next={
            next
              ? {
                  to: getCaseStudyUrl(next.slug),
                  title: `Case Study: ${next.title}`,
                }
              : undefined
          }
        />
      </Container>
    </>
  );
};
