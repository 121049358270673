import React from "react";
import { Layout } from "../components/Layout";
import { graphql, PageProps } from "gatsby";
import { Seo } from "../components/Seo";
import { CaseStudy } from "../components/CaseStudies/CaseStudy";
import { MdxImageRaw, MdxImageSharp, MdxOgImage } from "../types/images";
import { Technology } from "../utils/tech";
import { MdxTestimonialsData } from "../components/Testimonials/MdxTestimonials";
import { MdxListPageContext } from "../types/mdx";
import { MdxRenderer } from "../components/Mdx/MdxRenderer";

type PageData = {
  mdx?: {
    id: string;
    excerpt: string;
    body: string;
    frontmatter: {
      title: string;
      link: string;
      featured: MdxImageSharp & MdxImageRaw;
      ogImage: MdxOgImage;
      tech: Technology[];
      date: string;
      description: string;
    };
  };
  testimonial: MdxTestimonialsData;
};

export const query = graphql`
  query CaseStudyBySlug($slug: String!) {
    mdx(fields: { collection: { eq: "cases" }, slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        link
        featured {
          ...PageFeaturedData
          publicURL
        }
        ogImage: featured {
          ...OgImageData
        }
        tech
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    testimonial: allMdx(
      filter: {
        fields: { collection: { eq: "testimonials" }, slug: { eq: $slug } }
      }
    ) {
      ...TestimonialsData
    }
  }
`;

const CaseStudyTemplate: React.FC<PageProps<PageData, MdxListPageContext>> = ({
  data,
  pageContext,
}) => {
  const page = data.mdx;
  if (!page || !page.frontmatter) return null;

  const { frontmatter } = page;

  const { next, prev, slug } = pageContext;

  return (
    <Layout>
      <Seo
        article
        title={`Case Study: ${frontmatter.title}`}
        description={frontmatter.description || page.excerpt}
        ogImage={frontmatter.ogImage}
        maxImagePreview="large"
      />
      <CaseStudy
        slug={slug}
        title={frontmatter.title}
        description={frontmatter.description}
        websiteUrl={frontmatter.link}
        featured={frontmatter.featured}
        tech={frontmatter.tech}
        testimonial={data.testimonial}
        next={next}
        prev={prev}
      >
        <MdxRenderer>{page.body}</MdxRenderer>
      </CaseStudy>
    </Layout>
  );
};

export default CaseStudyTemplate;
